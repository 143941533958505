<template>
  <div class="formNewOrder">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <div>
      <h1>Criar nova encomenda</h1>
      {{ limitTax }}
      <div class="row">
        <div class="col-sm-3">
          <label for="orderSelected"
            ><h5>Escolher qual encomenda juntar:</h5></label
          >
          <input
            class="form-control"
            list="datalistOptions"
            id="exampleDataList"
            v-model="orderSelected"
            placeholder="Type to search..."
            @change="addNewOrder()"
          />
          <datalist id="datalistOptions">
            <option disabled value="">Escolha encomenda</option>
            <option
              v-for="order in othersOrders"
              :key="order.id"
              :value="order.id"
            >
              {{ order.id }}
            </option>
          </datalist>

          <!-- <select
            id="orderSelected"
            class="form-select"
            aria-label="Default select example"
            v-model="orderSelected"
            data-live-search="true"
            @change="addNewOrder()"
          >
            <option disabled value="">Escolha encomenda</option>
            <option
              v-for="order in othersOrders"
              :key="order"
              :value="order"
            >
              {{ order.id }}
            </option>
          </select> -->
        </div>
      </div>
      <div class="row">
        <h5>Encomendas Selecionadas:</h5>
        <div
          class="orders col-sm-1"
          v-for="choice in othersChoice"
          :key="choice"
        >
          {{ choice }} <br />
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="removeOrder(choice)"
            :disabled="list2.length > 0"
          ></button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5">
          <select
            size="10"
            class="form-control"
            id="list1"
            multiple
            v-model="select"
          >
            <option v-for="item1 in list1" :key="item1.id" :value="item1">
              x{{ item1.quantity }} | {{ item1.sku }} | {{ item1.name }} |
              {{ item1.total }}
              {{ currency }}
            </option>
          </select>
          <p>
            Total: <b>{{ total1 }}</b> {{ currency }}
          </p>
        </div>
        <div class="col-sm-2" id="buttons">
          <button
            id="arrow"
            class="btn btn-primary btn-lg btn-block"
            @click="allToRight"
          >
            &raquo;
          </button>
          <button
            id="arrow"
            class="btn btn-primary btn-lg btn-block"
            @click="oneToRight"
          >
            &rsaquo;
          </button>
          <button
            id="arrow"
            class="btn btn-primary btn-lg btn-block"
            @click="oneToLeft"
          >
            &lsaquo;
          </button>
          <button
            id="arrow"
            class="btn btn-primary btn-lg btn-block"
            @click="allToLeft"
          >
            &laquo;
          </button>
        </div>
        <div class="col-md-5">
          <!-- <select
            size="10"
            class="form-control"
            id="list2"
            multiple
            v-model="select2"
          > -->
          <table class="table">
            <thead></thead>
            <tbody>
              <tr v-for="(item2, index) in list2" :key="item2" :value="item2">
                <td><input type="text" v-model="list2[index].quantity" /></td>
                <td>
                  <input type="text" id="price" v-model="list2[index].price" />
                </td>
                <td>
                  {{
                    (list2[index].total =
                      Number(list2[index].price) *
                      Number(list2[index].quantity)).toFixed(2)
                  }}
                </td>
                <td>{{ item2.name }}</td>
              </tr>
            </tbody>
          </table>
          <!--  <option v-for="(item2, index) in list2" :key="item2" :value="item2">
              <input type="text" v-model="list2[index].quantity" /> 
              x{{
                item2.quantity
              }}
              | {{ item2.name }} |
              {{ item2.total }}
              {{ currency }}
            </option> -->
          <!-- </select> -->
          <p>
            Total:
            <b>{{
              (this.total2 = Number(
                this.list2.reduce((sum, product) => {
                  return sum + Number(product.total);
                }, 0)
              ).toFixed(2))
            }}</b>
            {{ currency }}
          </p>
          <p>
            <b>Limite de Taxação: </b> {{ limitTax.value }}
            {{ limitTax.currency }}
          </p>
        </div>
      </div>
    </div>
    <div>
      <h3>Validar dados do Cliente</h3>
      <form v-on:submit.prevent="createNewOrder()">
        <div class="row">
          <div class="col">
            <label for="firstName">Primerio Nome:</label>
            <input
              id="firstName"
              type="text"
              class="form-control"
              v-model="firstName"
            />
          </div>
          <div class="col">
            <label for="secondName">Apelido:</label>
            <input
              id="secondname"
              type="text"
              class="form-control"
              v-model="secondName"
            />
          </div>
          <div class="col">
            <label for="email">Email:</label>
            <input
              id="email"
              type="text"
              class="form-control"
              :placeholder="this.order.email"
              disabled
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="adress">Morada:</label>
            <input
              id="adress"
              type="text"
              class="form-control"
              v-model="finalAdress"
            />
          </div>
          <div class="col">
            <label for="city">Cidade:</label>
            <input id="city" type="text" class="form-control" v-model="city" />
          </div>
          <div class="col">
            <label for="country">País:</label>
            <input
              id="country"
              type="text"
              class="form-control"
              :placeholder="this.order.country"
              disabled
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="postalCode">Código Postal:</label>
            <div class="input-group mb-2">
              <input
                id="postalCode"
                type="text"
                class="form-control"
                v-model="postalCode"
              />
            </div>
          </div>
          <div class="col">
            <label for="phone">Contacto Telefónico:</label>
            <div class="input-group mb-2">
              <input
                id="phone"
                type="text"
                class="form-control"
                v-model="phone"
              />
            </div>
          </div>
          <div class="col">
            <label for="total">Total ({{ this.order.coin }}):</label>
            <input
              id="total"
              type="text"
              class="form-control"
              :placeholder="this.total2"
              disabled
            />
          </div>
          <div class="col">
            <label for="shippingCompany">Transportadora:</label>
            <select
              id="shippingCompany"
              class="form-select"
              v-model="shippingCompany"
            >
              <option>Free Shipping</option>
              <option>DHL</option>
              <option>FedEx</option>
              <option>CTT Expresso</option>
              <option>CTT</option>
              <option>Outro</option>
            </select>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col">
            <label for="">Número da Encomenda:</label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text">J-</div>
              </div>
              <input
                type="text"
                class="form-control"
                id="inlineFormInputGroup"
                placeholder="Nº Encomenda"
                v-model="orderNumber"
              />
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-sm-10"></div>
          <div class="col-sm-2">
            <button class="btn btn-primary mb-2" type="submit">ENVIAR</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "@/components/Loading.vue";
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      list1: [],
      list2: [],
      list2Original: [],
      allOrders: [],
      order: [],
      othersOrders: [],
      othersChoice: [],
      currency: "",
      select: [],
      orderSelected: "",
      total1: 0,
      total2: 0,
      select2: [],
      firstName: "",
      secondName: "",
      email: "",
      finalAdress: "",
      phone: "",
      city: "",
      postalCode: "",
      orderNumber: "",
      loading: false,
      shippingCompany: "",
      limitTax: "",
    };
  },
  methods: {
    oneToRight() {
      let sele = document.getElementById("list1").value;
      console.log(this.select[0]);
      if (sele != "") {
        this.list2.push(this.select[0]);
        this.list2Original.push(this.select[0]);
        let del = this.list1.indexOf(this.select[0]);
        this.list1.splice(del, 1);
        console.log(this.list2);
        this.calcTotal1();
        this.calcTotal2();
      }
    },
    oneToLeft() {
      console.log(this.select2[0]);
      let sele = document.getElementById("list2").value;
      if (sele != "") {
        this.list1.push(this.select2[0]);
        let del = this.list2.indexOf(this.select2[0]);
        this.list2.splice(del, 1);
      }
      this.calcTotal1();
      this.calcTotal2();
    },
    allToRight: function () {
      for (let i = 0; i < this.list1.length; i++) {
        this.list2.push(this.list1[i]);
        this.list2Original.push(this.list1[i]);
      }
      this.list1 = [];
      this.calcTotal1();
      this.calcTotal2();
    },
    allToLeft: function () {
      for (let i = 0; i < this.list2Original.length; i++) {
        this.list1.push(this.list2Original[i]);
      }
      this.list2Original = [];
      this.list2 = [];
      this.calcTotal1();
      this.calcTotal2();
    },
    async getAllOrders() {
      /* this.loading = true; */
      try {
        await this.$store.dispatch("allProcessingOrders");
        this.orders = this.getAllProcessingOrders;
        console.log(this.$route.params.orderId);
        console.log(this.orders);
        this.order = this.orders.find(
          (order) => order.id == this.$route.params.orderId
        );
        console.log(this.order);
        this.currency = this.order.coin;

        this.othersChoice.push(this.order.id);
        const help = JSON.parse(this.order.articles);
        for (const a of help) {
          a.order = this.order.id;
          this.list1.push(a);
        }
        this.othersOrders = this.orders.filter(
          (order) =>
            order.email == this.order.email &&
            order.id != this.order.id &&
            order.status == "processing" &&
            JSON.parse(order.articles[0]).length != 0
        );
        console.log(this.order);
        console.log(this.othersOrders);
        console.log(this.othersChoice);
        this.calcTotal1();
        this.firstName = this.order.first_name;
        this.secondName = this.order.second_name;
        this.email = this.order.email;
        this.finalAdress = this.order.adress1 + this.order.adress2;
        this.city = this.order.city;
        this.phone = this.order.phone;
        this.postalCode = this.order.postcode;
        this.orderNumber = this.order.id;
        /* await this.$store.dispatch("fetchOrderPicking");
        this.ordersPicking = this.getOrderPicking.data;
        this.getAllOrdersProcessing(); */
      } catch (err) {
        alert(err);
      }
    },
    async createNewOrder() {
      console.log("CRIAR NOVA ENCOMENDA");
      let advance = false;
      let passTax = false;
      if (
        this.limitTax.value < this.total2 &&
        this.limitTax.value != "Sem Limite"
      ) {
        await this.$swal({
          icon: "error",
          title: "Ooops",
          text: "Total da encomenda superior ao limite de taxação",
          confirmButtonText: "OK",
          showCancelButton: true,
          cancelButtonColor: "#3232dd",
          cancelButtonText: "Aceitar mesmo assim",
        }).then(async (result) => {
          if (!result.isConfirmed) {
            await this.$swal({
              title:
                "Insira o código de confirmação para criação de nova encomenda",
              html: `<input type="password" id="numberInvoice" class="form-control" v-model="teste" placeholder="Código">`,
              confirmButtonText: "Confirmar",
              cancelButtonText: "Cancelar",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              focusConfirm: false,
              preConfirm: () => {
                const code = this.$swal
                  .getPopup()
                  .querySelector("#numberInvoice").value;
                if (!code) {
                  this.$swal.showValidationMessage(
                    `Por favor preencha os campos corretamente`
                  );
                }
                return { code: code };
              },
            }).then(async (result2) => {
              if (result2.isConfirmed) {
                if (result2.value.code != "confirmo") {
                  await this.$swal({
                    icon: "error",
                    title: "Código de confirmação inválido",
                    text: "Encomenda não criada",
                  });
                  advance = false;
                } else {
                  advance = true;
                  passTax = true;
                }
              }
            });
          }
        });
      } else {
        advance = true;
      }
      if (advance) {
        this.loading = true;
        try {
          if (this.postalCode == "" || this.postalCode == undefined) {
            this.postalCode = "";
          }
          console.log(this.list2);
          console.log(this.order);
          if (this.shippingCompany == "" || this.shippingCompany == undefined)
            this.shippingCompany = this.order.shipping_company;
          await this.$store.dispatch("addNewOrder", {
            id: "J-" + this.orderNumber,
            articles: this.list2,
            address1: this.finalAdress,
            city: this.city,
            state: this.order.state,
            postCode: this.postalCode,
            country: this.order.country,
            shippingLines: this.order.shipping_lines,
            phone: this.phone,
            email: this.email,
            firstName: this.firstName,
            lastName: this.secondName,
            status: this.order.status,
            currency: this.currency,
            total: this.total2,
            paymentMethods: this.order.payment_method,
            feeLines: this.order.fee_lines,
            couponLines: this.order.coupon_lines,
            shippingCompany: this.shippingCompany,
            othersChoice: this.othersChoice,
            passTax: passTax,
          });
          console.log(this.othersChoice.length);
          for (let i = 0; i < this.othersChoice.length; i++) {
            console.log(this.othersChoice[i]);
            await this.removeProducts(this.othersChoice[i]);
          }
          try {
            await this.$store.dispatch("calculatePicking");
          } catch (err) {
            alert(err);
          }
          this.$router.push(`/orders/${this.getNewID}`);
        } catch (err) {
          this.$swal({
            icon: "error",
            title: "Ooops",
            text: err,
          });
        } finally {
          this.loading = false;
        }
      }
    },
    async addNewOrder() {
      console.log(this.orderSelected);
      let del = this.othersOrders.indexOf(this.orderSelected);
      this.othersOrders.splice(del, 1);
      let getOrder = this.orders.find(
        (order) => order.id == this.orderSelected
      );
      this.othersChoice.push(getOrder.id);
      let articles = JSON.parse(getOrder.articles);
      console.log(articles.length);
      for (let i = 0; i < articles.length; i++) {
        articles[i].order = getOrder.id;
        this.list1.push(articles[i]);
      }
      console.log(this.othersChoice);
      this.calcTotal1();
    },
    async calcTotal1() {
      let total = this.list1.reduce((sum, product) => {
        console.log(sum);
        console.log(product);
        console.log(product.total);
        return sum + Number(product.total);
      }, 0);
      this.total1 = total.toFixed(2);
      console.log(total);
    },
    async calcTotal2() {
      let total = this.list2.reduce((sum, product) => {
        console.log(sum);
        console.log(product);
        console.log(product.total);
        return sum + Number(product.price) * Number(product.quantity);
      }, 0);
      this.total2 = total.toFixed(2);
      console.log(total);
    },
    async removeOrder(id) {
      console.log(id);
      let getOrder = this.orders.find((order) => order.id == id);
      let articles = JSON.parse(getOrder.articles);
      console.log(this.list1);
      for (let i = 0; i < articles.length; i++) {
        let del1 = this.list1.findIndex((art) => art.id == articles[i].id);
        console.log(del1);
        if (del1 != -1) {
          this.list1.splice(del1, 1);
        } else {
          let del2 = this.list2.findIndex((art) => art.id == articles[i].id);
          if (del2 != -1) {
            this.list2.splice(del2, 1);
          }
        }
        this.calcTotal1();
        this.calcTotal2();
      }
      let del = this.othersChoice.indexOf(id);
      console.log(del);
      this.othersChoice.splice(del, 1);
      this.othersOrders.push(getOrder);
    },
    async removeProducts(id) {
      console.log("ID do removeProducts: ", id);
      let getOrder = this.orders.find((order) => order.id == id);
      console.log(getOrder);
      /* console.llist2og(getOrder.articles[0]); */
      let articles = JSON.parse(getOrder.articles[0]);
      console.log(articles);
      let newArticles = [];
      for (let i = 0; i < articles.length; i++) {
        // VERIFICAR QUAIS PRODUTOS EXISTEM NA LISTA 2 QUE TERÃO QUE SER REMOVIDOS DA ENCOMENDA ORIGINAL
        let index = this.list2.findIndex(
          (art) => art.id == articles[i].id && art.order == id
        );
        console.log(index);
        console.log("QUANTIDADE DA ENCOMENDA ORIGINAL: ", articles[i].quantity);
        if (index == -1) {
          console.log("NÃO EXISTE NA NOVA ENCOMENDA");
          newArticles.push(articles[i]);
        } else if (
          Number(articles[i].quantity) > Number(this.list2[index].quantity)
        ) {
          let newQuantity =
            Number(articles[i].quantity) - Number(this.list2[index].quantity);
          let newTotal = newQuantity * articles[i].price;
          console.log(
            "QUANTIDADE DA ENCOMENDA ORIGINAL: ",
            articles[i].quantity
          );
          console.log(
            "QUANTIDADE DA NOVA ENCOMENDA",
            this.list2[index].quantity
          );
          console.log("Nova Quantidade: " + newQuantity);
          articles[i].quantity = newQuantity;
          articles[i].total = newTotal.toFixed(2);
          articles[i].subtotal = newTotal.toFixed(2);
          console.log(articles[i]);
          newArticles.push(articles[i]);
          console.log("Novos valores");
        } else {
          console.log("Existe");
        }
      }
      console.log(newArticles);
      let total = newArticles.reduce((sum, product) => {
        console.log(sum);
        console.log(product);
        console.log(product.total);
        return sum + Number(product.total);
      }, 0);
      console.log(total);
      console.log(total);
      try {
        await this.$store.dispatch("putOrderArticles", {
          id: id,
          articles: newArticles,
          total: total.toFixed(2),
        });
      } catch (err) {
        alert(err);
      }
    },
    async reset() {
      await this.getAllOrders();
    },
    async getTaxacao() {
      try {
        const country = this.order.country;
        await this.$store.dispatch("getTaxa", {
          country: country,
        });
        this.limitTax = this.getTaxs;
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
  },
  async created() {
    /* AO CRIAR A PÁGINA CHAMA ESTA FUNÇÃO */
    await this.getAllOrders();
    await this.getTaxacao();
  },
  computed: {
    ...mapGetters(["getAllProcessingOrders"]),
    ...mapGetters(["getNewID"]),
    ...mapGetters(["getTaxs"]),
  },
};
</script>
<style scoped>
.row {
  margin: 50px;
}
.row #buttons {
  margin-left: auto;
  margin-right: auto;
  width: 50px;
}
.orders {
  text-align: center;
  border: 2px solid #ff9393;
  border-radius: 5px;
  font-size: 20px;
  margin-left: 12px;
  background: white;
}
.orders button {
  font-size: 12px;
  width: 10px;
  height: 13px;
}
select {
  border: medium solid #ff9393;
  border-radius: 10px;
}
input {
  border: 2px solid rgb(255, 147, 147);
}
.input-group-text {
  color: white;
  background-color: rgb(255, 147, 147);
  border: 2px solid rgb(255, 147, 147);
}

#inlineFormInputGroup {
  border-left-style: hidden;
}
h1 {
  margin: 50px 0px 0px 50px;
  color: #ff9393;
}
h3 {
  margin: 50px 0px 0px 50px;
  color: rgb(255, 147, 147);
}
::-webkit-scrollbar {
  width: 0px;
}
p {
  font-size: 20px;
  text-align: right;
}
#next {
  position: absolute;
  right: 60px;
}
#arrow {
  margin: 5px;
  width: 50px;
}
.formNewOrder {
  padding: 25px;
  background-color: #fff1f1;
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.table {
  display: block;
  height: 248px;
  overflow-y: auto;
  font-size: 14px;
}
td input {
  width: 50%;
  border-radius: 5px;
  text-align: center;
}
#price {
  width: 80%;
  border-radius: 5px;
  text-align: center;
}
</style>
